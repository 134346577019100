import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AppBar, Container, Grid, InputBase, Typography } from "@mui/material";
import GroupImage from "../../pages/images/favicon.png";
import logo from "../../pages/images/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import search from "../../pages/images/searchIcon.png";
import profile from "../../pages/images/profile.png";

import { useDispatch, useSelector } from "react-redux";

import { toggleSearchMode } from "../../redux/searchSlice";
import inputsearch from "../../pages/images/inputbase search.png";
import { searchContent } from "../../pages/search/searchState";

import logout from "../../pages/images/Logout Square.png";
import Accout from "../../pages/images/person.png";
import wishlist from "../../pages/images/wishlistMenu.png";
import History from "../../pages/images/HistoryMenu.png";
import Contact from "../../pages/images/contact.png";
import logoutIcon from "../../pages/images/LogoutIcon.png";
// import { auth } from "../../fireBase/firebaseauth"; // Import your Firebase config
//Dialog box

import axios from "axios";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  OAuthProvider,
} from "firebase/auth";

import { savePlaybackHistory } from "../../components/layout/Footer/profile/profileState";
import AuthDialog from "../AuthDialog";
import CustomSnackbar from "../Snackbar/snackbar";
import { apiEndpoint } from "../data/config";
import { saveHistoryUpdate } from "../../pages/VideoPlayer/videoPlayerState";

const Header = ({ historyData }) => {
  const auth = getAuth(); // Ensure auth is correctly initialized
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [code, setCode] = React.useState(false);
  const [activeButton, setActiveButton] = useState("/");

  const [searchText, setSearchText] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarImage, setSnackbarImage] = React.useState(""); // Dynamic image URL
  // eslint-disable-next-line no-unused-vars
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [firebaseUser, setFirebaseUser] = useState(null); // Store Firebase user info
  const dispatch = useDispatch();
  const isSearchMode = useSelector((state) => state.search.isSearchMode);
  const watchTime = useSelector((state) => state.video.watchTime); // Get watchTime from Redux

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);
  const handleCloseCode = () => setCode(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem("auth_token");
      setIsLoggedIn(!!token); // Update state based on whether token exists
    };

    checkLoginStatus();
  }, []);

  const handleMenuClickProfile = async (menuItem) => {
    setIsProfileDropdownOpen(false);

    if (menuItem === "History" && historyData) {
      savePlaybackHistory(historyData); // Trigger savePlaybackHistory if "History" is clicked
    }

    switch (menuItem) {
      case "Account":
        navigate("/account");
        break;
      case "Wishlist":
        navigate("/wishlist");
        break;
      case "History":
        navigate("/history");
        break;
      case "Contact":
        navigate("/contact");
        break;
      case "Sign Out":
        await handleLogout(); // Call the logout function when "Sign Out" is clicked
        break;
      default:
        break;
    }
  };

  const [previousPath, setPreviousPath] = useState(location.pathname);
  const [currentVideoHistory, setCurrentVideoHistory] = useState(null);
  useEffect(() => {
    if (historyData) {
      setCurrentVideoHistory(historyData);
    }
  }, [historyData]);

  // Listen for route changes using location.pathname
  useEffect(() => {
    if (currentVideoHistory && location.pathname !== previousPath) {
      // Call update API if leaving the VideoPlayer page
      saveHistoryUpdate({
        ...currentVideoHistory,
        watch_time: formatTime(watchTime), // Use Redux watchTime
      });

      setPreviousPath(location.pathname);
    }
  }, [location, currentVideoHistory]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const handleLogout = async () => {
    try {
      // Get the stored token (assuming it's in localStorage)
      const token = localStorage.getItem("auth_token");

      if (token) {
        // Make API request to logout
        const response = await axios.get(`${apiEndpoint}/api/app_user_logout`, {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });

        if (response.status === 200) {
          // Clear the token from localStorage
          localStorage.removeItem("auth_token");

          // Optionally clear other user data if needed
          // localStorage.removeItem('userData');

          // Navigate to the home or login page
          handleClickSnackbar("Logged Out Successfully", logoutIcon);
          navigate("/");
          window.location.reload();
        }
      } else {
        // No token found

        setIsLoggedIn(false); // Update state
        navigate("/"); // Redirect to home page if no token exists
        handleClickSnackbar("logout unsuccessful", logoutIcon);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized

        localStorage.removeItem("auth_token"); // Clear token

        // Update the logged-in state to false
        setIsLoggedIn(false);

        // Show the snackbar or any notification
        handleClickSnackbar(
          "Session expired, please sign in again.",
          logoutIcon
        );
        navigate("/"); // Redirect to home
        window.location.reload(); // Reload page to show "Sign in" button
      } else {
        // Handle other errors
        console.error(
          "Error during logout:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };
  //       } else {
  //         console.error("Failed to log out: ", response.status, response.data);
  //       }
  //     } else {
  //       console.warn("No token found");
  //       navigate("/"); // Redirect to home page if no token exists
  //       handleClickSnackbar("logout unsuccesfull", logoutIcon);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error during logout:",
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  // };

  // Handle OTP Verification
  const [phone, setPhone] = useState("");

  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [resendTimer, setResendTimer] = useState(120);

  // useEffect(() => {
  //   const token = localStorage.getItem("auth_token");
  //   if (token) {
  //     setIsLoggedIn(true); // User is logged in
  //   } else {
  //     setIsLoggedIn(false); // User is not logged in
  //   }
  // }, []);

  // const [otp, setOtp] = useState([
  //   "",
  //   "",
  //   "",
  //   "",
  //   "",
  //   "",
  // ]);
  // const [otp, setOtp] = useState(Array(verificationCode.length).fill(""));
  // const generateRecaptcha = () => {
  //   if (!window.recaptchaVerifier) {
  //     const recaptchaContainer = document.getElementById("recaptcha-container");
  //     if (!recaptchaContainer) {

  //       return;
  //     }

  //     if (!auth) {

  //       return;
  //     }

  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: (response) => {

  //         },
  //       },
  //       auth
  //     );

  //     window.recaptchaVerifier
  //       .render()
  //       .then((widgetId) => {
  //         window.recaptchaWidgetId = widgetId;
  //       })
  //       .catch((error) => {
  //         console.error("Error rendering reCAPTCHA widget:", error);
  //       });
  //   } else {
  //     // console.log("reCAPTCHA already rendered.");
  //   }
  // };

  // useEffect(() => {
  //   generateRecaptcha();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);

  // const handleSend = (event) => {
  //   event.preventDefault();
  //   setCode(true);

  //   generateRecaptcha();
  //   let appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, phone, appVerifier)
  //     .then((confirmationResult) => {
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code).
  //       window.confirmationResult = confirmationResult;
  //       setCode(true);
  //       setResendTimer(120); // Reset the timer to 2 minutes
  //     })
  //     .catch((error) => {
  //       // Error; SMS not sent
  //       console.log(error);
  //     });
  // };

  // const verifyOtp = async () => {
  //   const otpString = otp.join("");

  //   if (otpString.length === 6 && window.confirmationResult) {
  //     try {

  //       const result = await window.confirmationResult.confirm(otpString);
  //       const user = result.user;
  //       setFirebaseUser(user);

  //       const payload = { phone: phone };
  //       const response = await axios.post(

  //         `${apiEndpoint}/api/app_otp_login`,
  //         payload
  //       );

  //       if (response.status === 200) {
  //         const { token: backendToken } = response.data.data;
  //         if (backendToken) {
  //           localStorage.setItem("auth_token", backendToken);

  //           handleClickSnackbar("Logged in successfully!");
  //           navigate("/homePage");
  //         } else {

  //           handleClickSnackbar("Failed to Login!");
  //         }
  //       } else {

  //         handleClickSnackbar("Failed to Login!");
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
  //         setIsUnauthorized(true); // Show sign-in button when unauthorized
  //         handleClickSnackbar("Session expired. Please sign in again.");
  //       }
  //     }
  //   } else {

  //     // alert("OTP is not valid or confirmation result is missing.");
  //     handleClickSnackbar("OTP is not valid.Please try again  ");
  //   }
  // };
  const appleLogin = async () => {
    const provider = new OAuthProvider("apple.com"); // Initialize the Apple OAuth provider

    try {
      // Trigger Apple Sign-In popup
      const result = await signInWithPopup(auth, provider);

      // Get user info from Apple result
      const user = result.user;
      const token = await user.getIdToken(); // Get Firebase token

      // Prepare user data to send to your backend (if needed)
      const userData = {
        name: user.displayName || "Anonymous", // Apple does not always provide the full name
        email: user.email,
        phone: user.phoneNumber,
      };

      // Post user data to your custom backend API
      const response = await axios.post(
        `${apiEndpoint}/api/app_social_login`, // Your custom backend endpoint
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send Firebase token to the backend
          },
        }
      );

      if (response.status === 200) {
        // Extract token from backend response
        const { token: backendToken } = response.data.data;

        if (backendToken) {
          // Store the backend token in localStorage
          localStorage.setItem("auth_token", backendToken);

          // Redirect user or close login modal
          setOpen(false);
          navigate("/homePage");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsUnauthorized(true); // Show sign-in button when unauthorized
        handleClickSnackbar("Session expired. Please sign in again.");
      }
    }
  };
  // Function to focus the next input field

  const handleResendCode = () => {
    // Logic to resend OTP

    // Reset the timer
    setResendTimer(55);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setActiveButton(location.pathname);
  }, [location]);

  const handleButtonClick = (value) => {
    const token = localStorage.getItem("auth_token"); // Retrieve the stored token

    // If the user is trying to navigate to Movies, Live TV, or TV Series
    if (
      value === "/movies" ||
      value === "/tvSeriesPage" ||
      value === "/livetv" ||
      value === "/songs" ||
      value === "/homePage"
    ) {
      // Check if the user is logged in
      if (!token) {
        console.log("User not logged in. Showing login modal.");
        setOpen(true); // Show login modal
        return; // Prevent navigation
      }
    }

    // If the value is "/" (home page), toggle search mode off
    if (value === "/") {
      dispatch(toggleSearchMode(false)); // Set isSearchMode to false on landing page
    }

    setActiveButton(value); // Set the active button
    navigate(value); // Navigate to the clicked page
  };

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(toggleSearchMode(false)); // Reset isSearchMode when on the landing page
    }
  }, [location, dispatch]);
  const buttonStyle = (theme) => ({
    minWidth: "95px",
    height: "32px",
    borderRadius: "99px",
    color: "white",
    fontWeight: 500,
    fontSize: "15.9px",
    lineHeight: "20px",
    textTransform: "inherit",
    fontFamily: "Inter",
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "70px",
      fontSize: "12px",
    },
  });

  // const buttonStyle = {
  //   minWidth: "95px",
  //   height: "32px",
  //   borderRadius: "99px",
  //   color: "white",
  //   fontWeight: 500,
  //   fontSize: "15.9px",
  //   lineHeight: "20px",
  //   textTransform: "inherit",
  //   fontFamily: "Inter",
  //   background: "transparent",
  //   boxShadow: "none",
  //   "&:hover": {
  //     boxShadow: "none",
  //   },

  //   "@media (max-width: 1200px)": {
  //     minWidth: "70px",
  //     // height: "24px",
  //     fontSize: "12px",
  //   },
  // };

  // Style for active buttons
  const activeButtonStyle = {
    borderRadius: "99px",
    minWidth: "99px",
    border: "2px solid rgba(255, 255, 255, 0.13)",
    background:
      "linear-gradient(to left,#280F48, #280F48) padding-box,linear-gradient(260deg, #885CC0,#442E60) border-box",
    boxShadow: `
      0px 1px 0px 0px rgba(0, 0, 0, 0.05),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05),
      0px 10px 10px 0px rgba(0, 0, 0, 0.10)
    `,
    backdropFilter: "blur(10px)",
    borderImageSource: `
      linear-gradient(0deg, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13)),
      linear-gradient(86.25deg, rgba(255, 255, 255, 0) 6.77%, #FFFFFF 88.01%)
    `,
    textTransform: "inherit",
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Effect to handle countdown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);
  //Profile

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const handleProfileClick = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const login = async () => {
    const provider = new GoogleAuthProvider();

    try {
      // Trigger Google Sign-In popup
      const result = await signInWithPopup(auth, provider);

      // Get user info from Google result
      const user = result.user;
      const token = await user.getIdToken(); // Firebase token if needed for future use

      const userData = {
        name: user.displayName,
        email: user.email,
        phone_number: user.phoneNumber,
        birth_date: "1990-01-01", // Example birth date, change as required
      };

      // Post user data to your custom backend API
      const response = await axios.post(
        `${apiEndpoint}/api/app_social_login`,

        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Optional: if you want to pass the Firebase token
          },
        }
      );

      if (response.status === 200) {
        // Check the structure of response.data

        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure

        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);

          // Handle successful login response
          setOpen(false);
          navigate("/homePage");
          // alert("Login is successful");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        // Handle non-200 status
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
      if (error.response && error.response.status === 401) {
        setIsUnauthorized(true); // Show sign-in button when unauthorized
        handleClickSnackbar("Session expired. Please sign in again.");
      }
    }
  };

  ///////////////////////////////////////////////otp login////////////////////////////////////////

  useEffect(() => {
    // Timer for resending OTP
    if (resendTimer > 0) {
      const timerId = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [resendTimer]);

  // useEffect(() => {
  //   if (historyData) {
  //     savePlaybackHistory(historyData);
  //   }
  // }, [historyData]);

  useEffect(() => {
    if (historyData?.content_id && historyData?.total_duration) {
      savePlaybackHistory(historyData);
    }
  }, [historyData]);

  useEffect(() => {
    if (showNavigation) {
    }
  });
  const handleSearchChange = async (event) => {
    setSearchText(event.target.value); // Update searchText with user input
  };

  const [showNavigation, setShowNavigation] = useState(true);
  const [isInitialSearch, setIsInitialSearch] = useState(true);

  const handleSearchClick = async () => {
    if (isInitialSearch) {
      dispatch(toggleSearchMode());

      try {
        const results = await searchContent(searchText);

        let navigateTo = "";

        if (location.pathname.includes("movies")) {
          navigateTo = `/explore/movies?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("tvSeries")) {
          navigateTo = `/explore/tvSeries?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("livetv")) {
          navigateTo = `/explore/livetv?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("songs")) {
          navigateTo = `/explore/songssearch?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("homePage")) {
          navigateTo = `/explore/popularsearch?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("popularsearch")) {
          navigateTo = `/explore/popularsearch?search=${encodeURIComponent(
            searchText
          )}`;
        }

        if (navigateTo) {
          navigate(navigateTo, { state: { searchResults: results.data } });
          setShowNavigation(false); // Hide navigation items during search
        }
        setIsInitialSearch(false); // Set the flag to false after redirection
      } catch (error) {
        console.error("Error performing search:", error);
      }
    } else {
      // Subsequent search clicks: perform search operation on the current page
      try {
        const results = await searchContent(searchText);
        console.log(results, "results");
        // Handle the search results as needed here
      } catch (error) {
        console.error("Error performing search:", error);
      }
    }
  };
  const [isInputVisible, setIsInputVisible] = useState(true);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchClick();
    } else if (event.key === "Escape") {
      setSearchText("");
      setIsInputVisible(false); // Hide the input field
    }
  };

  useEffect(() => {
    // Reset search state on location change
    setSearchText("");
    setIsInitialSearch(true);
  }, [location]);

  const [mobileNumber, setMobileNumber] = useState(""); // example mobile number
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Initialize as an array of empty strings
  const [sessionId, setSessionId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [verificationStatus, setVerificationStatus] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loginData, setLoginData] = useState(null);
  // const [isUnauthorized, setIsUnauthorized] = useState(false);
  const sendOtp = async () => {
    try {
      const fullPhoneNumber = `${countryCode}${mobileNumber}`;
      const payload = {
        mobile_number: fullPhoneNumber, // Example: "+919902858800"
      };
      const response = await axios.post(
        // 'https://admin.evangel.stream/api/send_user_otp',
        `${apiEndpoint}/api/send_user_otp`,
        payload

        // { mobile_number: mobileNumber }
      );

      if (response.data.Status === "Success") {
        setSessionId(response.data.Details);
        setCode(true);

        handleClickSnackbar("OTP sent successfully!");
      } else {
        alert("Failed to send OTP");
        handleClickSnackbar("OTP is not sent!");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Error sending OTP");
    }
  };

  // Step 2: Function to verify OTP
  // const verifySessionOtp = async () => {
  //   if (!sessionId) {
  //     alert('Session ID is missing. Please send OTP first.');
  //     return;
  //   }

  //   try {
  //     // Convert the OTP array to a single string
  //     const otpString = otp.join(''); // Join OTP array elements into a single string

  //     const response = await axios.post(
  //       // 'https://admin.evangel.stream/api/verify_user_otp',
  //       `${apiEndpoint}/api/verify_user_otp`,
  //       {
  //         session_id: sessionId,
  //         user_otp: otpString,  // Send OTP as a string
  //       }
  //     );
  //     console.log(response, "ressssss");

  //     if (response.data.Status === 'Success') {
  //       setVerificationStatus('OTP Verified Successfully');

  //       loginWithOtp();
  //     //  navigate("/homePage");
  //       // Navigate to home page  // Call the login function after successful OTP verification
  //     }
  //      else {
  //       setVerificationStatus('OTP Verification Failed');
  //       handleClickSnackbar("Invalid otp please try again.");
  //     }
  //   } catch (error) {
  //     console.error('Error verifying OTP:', error);
  //     setVerificationStatus('Error verifying OTP');
  //   }
  // };

  const verifySessionOtp = async () => {
    if (!sessionId) {
      alert("Session ID is missing. Please send OTP first.");
      return;
    }

    try {
      // Convert the OTP array to a single string
      const otpString = otp.join(""); // Join OTP array elements into a single string

      const response = await axios.post(`${apiEndpoint}/api/verify_user_otp`, {
        session_id: sessionId,
        user_otp: otpString, // Send OTP as a string
      });

      if (response.data.Status === "Success") {
        setVerificationStatus("OTP Verified Successfully");
        await loginWithOtp(); // Call the login function after successful OTP verification
      } else {
        setVerificationStatus("OTP Verification Failed");
        handleClickSnackbar("Invalid OTP. Please try again."); // Show snackbar for invalid OTP
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);

      if (error.response) {
        if (error.response.status === 400) {
          // Handle 400 error specifically
          handleClickSnackbar("Invalid OTP. Please try again.");
        } else if (error.response.status === 401) {
          setIsUnauthorized(true);
          handleClickSnackbar("Session expired. Please sign in again.");
        } else {
          handleClickSnackbar("Error verifying OTP. Please try again later.");
        }
      } else {
        handleClickSnackbar("Network error, please check your connection.");
      }

      setVerificationStatus("Error verifying OTP");
    }
  };
  const [countryCode, setCountryCode] = useState("+91"); // Initialize with default code

  const loginWithOtp = async () => {
    const otpString = otp.join("");

    if (otpString.length === 6) {
      try {
        // const fullPhoneNumber = `${countryCode}${mobileNumber}`;

        const payload = {
          phone_number: mobileNumber,
          country_code: countryCode,
        };

        console.log(payload, "payloasdss");
        const response = await axios.post(
          `${apiEndpoint}/api/app_otp_login`,
          payload
        );

        if (response.status === 200 && response.data.status === "Success") {
          const { token: backendToken } = response.data.data;
          if (backendToken) {
            localStorage.setItem("auth_token", backendToken);

            handleClickSnackbar("Logged in successfully!");

            navigate("/homePage"); // Navigate to home page

            // Set login state to true to trigger navigation
            setIsLoggedIn(true);
            navigate("/homePage"); // Navigate to home page
          } else {
            handleClickSnackbar("Failed to Login!");
          }
        } else {
          handleClickSnackbar("Failed to Login!");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsUnauthorized(true);
          handleClickSnackbar("Session expired. Please sign in again.");
        } else {
          console.error("Error during OTP login:", error);
          handleClickSnackbar("Error during OTP login");
        }
      }
    } else {
      handleClickSnackbar("OTP is not valid. Please try again.");
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          zIndex: 1100,
          top: "30px",
        }}
      >
        {/* <Grid classes="data"></Grid> */}
        {/* <div style={{
           width: '100%', 
         
           height: '236px', 
           top: '-70%', 
           left: '-91.86', // Position from the left edge of the image
         
           background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)',
           position: 'absolute', // Position absolutely within the parent container
       filter: "blur(12px)",
        }}></div> */}
        <Box
          sx={{
            width: "100%",
            height: "236px",
            left: "-91.86", // Position from the left edge of the image
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)",
            position: "absolute",
            filter: "blur(12px)",
            top: {
              xs: "-125%", // 125% for small screens
              md: "-70%", // -70% for medium and larger screens
            },
          }}
        ></Box>

        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "flex",
              },
            }}
          >
            <Grid
              container
              item
              md={2}
              lg={2}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",

                  marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              container
              item
              md={10}
              lg={10}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "52px",
                    top: "30px",
                    minWidth: "813px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      minWidth: "813px",
                      height: "52px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                            alignItems: "flex-end",
                            position: "relative",
                            bottom: "-5px",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={8} lg={9} md={8} sm={8}>
                      {!isSearchMode ? (
                        <Box
                          sx={{
                            width: "392px",
                            height: "32px",
                            gap: "5px",
                            display: "flex",
                          }}
                        >
                          <Button
                            onClick={() => handleButtonClick("/homePage")}
                            // sx={{
                            //   ...buttonStyle,
                            //   ...(activeButton === "/homePage"
                            //     ? activeButtonStyle
                            //     : {}),
                            // }}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/homePage"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Home
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/movies")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/movies"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Movies
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/tvSeriesPage")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),

                              ...(activeButton === "/tvSeriesPage"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            TV Series
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/songs")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/songs"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Songs
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/livetv")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/livetv"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Live TV
                          </Button>
                        </Box>
                      ) : (
                        isInputVisible && (
                          <Box
                            sx={{
                              width: "392px",
                              height: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <InputBase
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyDown={handleKeyPress}
                              placeholder="Search..."
                              classes="searchInput"
                              sx={{
                                paddingTop: "0",
                                paddingBottom: "0",
                              }}
                            />
                          </Box>
                        )
                      )}
                    </Grid>

                    <Grid item xs={3} lg={2} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {[
                          "/homePage",
                          "/movies",
                          "/tvSeriesPage",
                          "/livetv",
                          "/songs",
                          "/explore/movies",
                          "/explore/tvSeries",
                          "/explore/songs",
                          "/explore/livetv",
                          "/explore/songssearch",
                          "/explore/popularsearch",
                        ].includes(activeButton) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              // onClick={handleSearchClick}
                              onClick={handleSearchClick}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "34px",
                                display: "flex",
                                justifyContent: "end",
                                // alignItems: "flex-end",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                              // onClick={handleClickOpen}
                              // onClick={() => {
                              //   // Handle avatar icon click
                              // }}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Box>
                              {isLoggedIn ? (
                                <Button
                                  style={{
                                    height: "34px",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0 !important",
                                  }}
                                  onClick={handleProfileClick}
                                >
                                  <img src={profile} alt="profile" />
                                </Button>
                              ) : (
                                <Button
                                  // onClick={handleClickOpen}
                                  onClick={handleOpenDialog}
                                  sx={(theme) => ({
                                    ...buttonStyle(theme),

                                    width: "83px",
                                    padding:
                                      "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                    gap: "var(--Buttongap)",
                                    borderRadius: "99px",
                                    border: "1px solid transparent",
                                    fontFamily: "Inter",
                                    background:
                                      "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                    boxShadow:
                                      "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                    fontSize: "14px",
                                    borderImage:
                                      "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                    textTransform: "initial",
                                    backdropFilter: "blur(10px)",
                                    marginTop: "2px",
                                  })}
                                >
                                  Sign in
                                </Button>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={2} lg={2}></Grid>
          </Grid>
        </Container>
        <Grid
          item
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "flex",
            },
          }}
        >
          {isProfileDropdownOpen && (
            <Box
              sx={{
                height: { xs: "auto", md: "304.85px" }, // Height adjusts for smaller screens
                borderRadius: "13.21px",
                position: "relative",
                background:
                  "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                width: { xs: "100%", sm: "300px", md: "264.27px" }, // Adjust width for different screen sizes
                left: { xs: 0, md: "59%", lg: "60%", xl: "60%" }, // Center the dropdown on smaller screens
                top: { xs: "0px", md: "10px", lg: "10px", sm: "50px" }, // Position the dropdown below the trigger on smaller screens
                backdropFilter: "blur(13.21367073059082px)",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  borderRadius: "13.21px",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  padding: "2px",
                  zIndex: "20",
                  overflow: "hidden",
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                  borderImageSlice: "1",
                  mixBlendMode: "overlay",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                  boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  zIndex: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  fontFamily: "Inter important",
                  fontWeight: 700,
                  padding: { xs: "16px", md: "24px", lg: "24px", sm: "16px" }, // Adjust padding for different screen sizes
                }}
              >
                {[
                  {
                    name: "Account",
                    icon: (
                      <img
                        src={Accout}
                        alt="account"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "Wishlist",
                    icon: (
                      <img
                        src={wishlist}
                        alt="wishlist"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "History",
                    icon: (
                      <img
                        src={History}
                        alt="history"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "Contact",
                    icon: (
                      <img
                        src={Contact}
                        alt="contact"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "Sign Out",
                    icon: (
                      <img
                        src={logout}
                        alt="sign out"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                ].map((item, index) => (
                  <Box key={item.name}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "12px",
                        borderLeft: "1.321px solid transparent",
                        marginLeft: "8px",
                        background: "transparent",
                        transition: "background 0.3s ease",
                        cursor: "pointer",
                        paddingLeft: "8px", // Adds space between the border and content
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                          borderLeft: "1.321px solid rgba(255, 255, 255, 1)",
                        },
                        "&:active": {
                          background:
                            "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                          borderLeft: "1.321px solid rgba(255, 255, 255, 1)",
                        },
                      }}
                      onClick={() => handleMenuClickProfile(item.name)}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: { xs: "16px", md: "18.499px" }, // Adjust font size for different screen sizes
                          fontWeight: 500,
                          color:
                            item.name === "Sign Out"
                              ? "rgba(255, 93, 239, 1) "
                              : "rgba(255, 255, 255, 1)",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Box
                        sx={{
                          marginLeft: "auto", // Pushes the icon to the right
                          color:
                            item.name === "Sign Out"
                              ? "rgba(219, 0, 126, 1)"
                              : "rgba(255, 255, 255, 1)",
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                    {index < 4 && (
                      <Box
                        sx={{
                          borderRadius: "13.214px",
                          background:
                            "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                          height: "1.321px",
                          alignSelf: "stretch",
                          margin: { xs: "4px 8px", md: "8px 16px" }, // Adjust margin for different screen sizes
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Grid>

        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
                xl: "none",
              },
            }}
          >
            <Grid
              item
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",

                  // marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              lg={9}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "52px",
                    top: "30px",
                    minWidth: "670px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      minWidth: "670px",
                      height: "52px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                            alignItems: "flex-end",
                            position: "relative",
                            bottom: "-5px",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={9} md={8} sm={8}>
                      {!isSearchMode ? (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            // gap: "18px",
                            display: "flex",
                          }}
                        >
                          <Button
                            onClick={() => handleButtonClick("/homePage")}
                            // sx={{
                            //   ...buttonStyle,
                            //   ...(activeButton === "/homePage"
                            //     ? activeButtonStyle
                            //     : {}),
                            // }}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/homePage"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Home
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/movies")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/movies"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Movies
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/tvSeriesPage")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/tvSeriesPage"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            TV Series
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/songs")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/songs"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Songs
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/livetv")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/livetv"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Live TV
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative", // Add this to position the icon correctly
                          }}
                        >
                          <InputBase
                            value={searchText}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            classes="searchInput"
                            sx={{
                              paddingTop: "0",
                              paddingBottom: "0",
                            }}
                          />

                          <img
                            src={inputsearch}
                            alt="inputsearch"
                            style={{
                              position: "absolute",
                              left: "10px",
                            }}
                          />
                          {/* <SearchIcon
                      sx={{
                        position: 'absolute',
                        left: '10px', // Adjust this value to position the icon within the box
                        color: 'rgba(0, 0, 0, 0.54)', // Adjust the color if necessary
                      }}
                    /> */}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={3} lg={2} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {[
                          "/homePage",
                          "/movies",
                          "/tvSeriesPage",
                          "/livetv",
                          "/songs",
                          "/explore/movies",
                          "/explore/tvSeries",
                          "/explore/songs",
                          "/explore/livetv",
                          "/explore/songssearch",
                          "/explore/popularsearch",
                        ].includes(activeButton) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              // onClick={handleSearchClick}
                              onClick={handleSearchClick}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "34px",
                                display: "flex",
                                justifyContent: "end",
                                // alignItems: "flex-end",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                              // onClick={handleClickOpen}
                              // onClick={() => {
                              //   // Handle avatar icon click
                              // }}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            {/* <Button
                              style={{
                                ...buttonStyle,
                                width: "72px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              Log in
                            </Button> */}
                            {/* <Button
                              onClick={handleClickOpen}
                              style={{
                                ...buttonStyle,
                                width: "83px",
                                padding:
                                  "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                gap: "var(--Buttongap)",
                                borderRadius: "99px",
                                border: "1px solid transparent",
                                fontFamily: "Inter",
                                background:
                                  "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                boxShadow:
                                  "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                fontSize: "14px",
                                borderImage:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                textTransform: "initial",
                                backdropFilter: "blur(10px)",
                                marginTop: "2px",
                              }}
                            >
                              Sign In
                            </Button> */}
                            <Box>
                              {isLoggedIn ? (
                                <Button
                                  style={{
                                    height: "34px",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0 !important",
                                  }}
                                  onClick={handleProfileClick}
                                >
                                  <img src={profile} alt="profile" />
                                </Button>
                              ) : (
                                <Button
                                  onClick={handleClickOpen}
                                  sx={(theme) => ({
                                    ...buttonStyle(theme),
                                    width: "83px",
                                    padding:
                                      "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                    gap: "var(--Buttongap)",
                                    borderRadius: "99px",
                                    border: "1px solid transparent",
                                    fontFamily: "Inter",
                                    background:
                                      "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                    boxShadow:
                                      "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                    fontSize: "14px",
                                    borderImage:
                                      "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                    textTransform: "initial",
                                    backdropFilter: "blur(10px)",
                                    marginTop: "2px",
                                  })}
                                >
                                  Sign in
                                </Button>
                              )}
                            </Box>
                          </>
                        )}

                        {isProfileDropdownOpen && (
                          <Box
                            sx={{
                              height: { xs: "auto", md: "304.85px" },
                              borderRadius: "13.21px",
                              position: "absolute", // Changed to absolute positioning
                              background:
                                "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                              width: {
                                xs: "100%",
                                sm: "300px",
                                md: "264.27px",
                              },
                              left: {
                                xs: 0,
                                md: "auto",
                                lg: "auto",
                                sm: "auto",
                              },
                              right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                              top: {
                                xs: "50px",
                                md: "50px",
                                lg: "90px",
                                sm: "50px",
                              },
                              backdropFilter: "blur(13.21367073059082px)",
                              zIndex: 9999, // Ensure it overlays other content
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                borderRadius: "13.21px",
                                height: "100%",
                                top: "0px",
                                left: "0px",
                                right: "0px",
                                padding: "2px",
                                zIndex: "20",
                                overflow: "hidden",
                                background:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                                borderImageSlice: "1",
                                mixBlendMode: "overlay",
                                WebkitMask:
                                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                WebkitMaskComposite: "xor",
                                maskComposite: "exclude",
                                boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                              }}
                            />
                            <Box
                              sx={{
                                position: "relative",
                                zIndex: 30,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                                fontFamily: "Inter important",
                                fontWeight: 700,
                                padding: {
                                  xs: "16px",
                                  md: "24px",
                                  lg: "24px",
                                  sm: "16px",
                                },
                              }}
                            >
                              {[
                                {
                                  name: "Account",
                                  icon: (
                                    <img
                                      src={Accout}
                                      alt="account"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Wishlist",
                                  icon: (
                                    <img
                                      src={wishlist}
                                      alt="wishlist"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "History",
                                  icon: (
                                    <img
                                      src={History}
                                      alt="history"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Contact",
                                  icon: (
                                    <img
                                      src={Contact}
                                      alt="contact"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Sign Out",
                                  icon: (
                                    <img
                                      src={logout}
                                      alt="sign out"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                              ].map((item, index) => (
                                <Box key={item.name}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "12px",
                                      borderLeft: "1.321px solid transparent",
                                      marginLeft: "8px",
                                      background: "transparent",
                                      transition: "background 0.3s ease",
                                      cursor: "pointer",
                                      paddingLeft: "8px", // Adds space between the border and content
                                      "&:hover": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                      "&:active": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                    }}
                                    onClick={() =>
                                      handleMenuClickProfile(item.name)
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Inter",
                                        fontSize: {
                                          xs: "16px",
                                          md: "18.499px",
                                        }, // Adjust font size for different screen sizes
                                        fontWeight: 500,
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(255, 93, 239, 1) "
                                            : "rgba(255, 255, 255, 1)",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        marginLeft: "auto", // Pushes the icon to the right
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(219, 0, 126, 1)"
                                            : "rgba(255, 255, 255, 1)",
                                      }}
                                    >
                                      {item.icon}
                                    </Box>
                                  </Box>
                                  {index < 4 && (
                                    <Box
                                      sx={{
                                        borderRadius: "13.214px",
                                        background:
                                          "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                        height: "1.321px",
                                        alignSelf: "stretch",
                                        margin: {
                                          xs: "4px 8px",
                                          md: "8px 16px",
                                        }, // Adjust margin for different screen sizes
                                      }}
                                    />
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={2} lg={2}></Grid>
          </Grid>
        </Container>

        {/* <Container> */}
        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <Grid
              item
              md={2.9}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",

                  // marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              lg={8}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "52px",
                    top: "30px",
                    minWidth: "580px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      minWidth: "580px",
                      height: "52px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                            alignItems: "flex-end",
                            position: "relative",
                            bottom: "-5px",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={9} md={8} sm={8}>
                      {!isSearchMode ? (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            // gap: "18px",
                            display: "flex",
                          }}
                        >
                          <Button
                            onClick={() => handleButtonClick("/homePage")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/homePage"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Home
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/movies")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/movies"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Movies
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/tvSeriesPage")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/tvSeriesPage"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            TV Series
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/songs")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/songs"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Songs
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/livetv")}
                            sx={(theme) => ({
                              ...buttonStyle(theme),
                              ...(activeButton === "/livetv"
                                ? activeButtonStyle
                                : {}),
                            })}
                          >
                            Live TV
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative", // Add this to position the icon correctly
                          }}
                        >
                          <InputBase
                            value={searchText}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            classes="searchInput"
                            sx={{
                              paddingTop: "0",
                              paddingBottom: "0",
                            }}
                          />

                          <img
                            src={inputsearch}
                            alt="inputsearch"
                            style={{
                              position: "absolute",
                              left: "10px",
                            }}
                          />
                          {/* <SearchIcon
                      sx={{
                        position: 'absolute',
                        left: '10px', // Adjust this value to position the icon within the box
                        color: 'rgba(0, 0, 0, 0.54)', // Adjust the color if necessary
                      }}
                    /> */}
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={3} lg={2} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {[
                          "/homePage",
                          "/movies",
                          "/songs",
                          "/tvSeriesPage",
                          "/livetv",
                          "/explore/movies",
                          "/explore/tvSeries",
                          "/explore/livetv",
                          "/explore/songssearch",

                          "/explore/popularsearch",
                        ].includes(activeButton) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              // onClick={handleSearchClick}
                              onClick={handleSearchClick}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "34px",
                                display: "flex",
                                justifyContent: "end",
                                // alignItems: "flex-end",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                              // onClick={handleClickOpen}
                              // onClick={() => {
                              //   // Handle avatar icon click
                              // }}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            {/* <Button
                              style={{
                                ...buttonStyle,
                                width: "72px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              Log in
                            </Button> */}
                            <Box>
                              {isLoggedIn ? (
                                <Button
                                  style={{
                                    height: "34px",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0 !important",
                                  }}
                                  onClick={handleProfileClick}
                                >
                                  <img src={profile} alt="profile" />
                                </Button>
                              ) : (
                                <Button
                                  onClick={handleClickOpen}
                                  sx={(theme) => ({
                                    ...buttonStyle(theme),
                                    width: "83px",
                                    padding:
                                      "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                    gap: "var(--Buttongap)",
                                    borderRadius: "99px",
                                    border: "1px solid transparent",
                                    fontFamily: "Inter",
                                    background:
                                      "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                    boxShadow:
                                      "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                    fontSize: "14px",
                                    borderImage:
                                      "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                    textTransform: "initial",
                                    backdropFilter: "blur(10px)",
                                    marginTop: "2px",
                                  })}
                                >
                                  Sign in
                                </Button>
                              )}
                            </Box>
                          </>
                        )}

                        {isProfileDropdownOpen && (
                          <Box
                            sx={{
                              height: { xs: "auto", md: "304.85px" },
                              borderRadius: "13.21px",
                              position: "absolute", // Changed to absolute positioning
                              background:
                                "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                              width: {
                                xs: "100%",
                                sm: "300px",
                                md: "264.27px",
                              },
                              left: {
                                xs: 0,
                                md: "auto",
                                lg: "auto",
                                sm: "auto",
                              },
                              right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                              top: {
                                xs: "50px",
                                md: "90px",
                                lg: "90px",
                                sm: "50px",
                              },
                              backdropFilter: "blur(13.21367073059082px)",
                              zIndex: 9999, // Ensure it overlays other content
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                borderRadius: "13.21px",
                                height: "100%",
                                top: "0px",
                                left: "0px",
                                right: "0px",
                                padding: "2px",
                                zIndex: "20",
                                overflow: "hidden",
                                background:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                                borderImageSlice: "1",
                                mixBlendMode: "overlay",
                                WebkitMask:
                                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                WebkitMaskComposite: "xor",
                                maskComposite: "exclude",
                                boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                              }}
                            />
                            <Box
                              sx={{
                                position: "relative",
                                zIndex: 30,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                                fontFamily: "Inter important",
                                fontWeight: 700,
                                padding: {
                                  xs: "16px",
                                  md: "24px",
                                  lg: "24px",
                                  sm: "16px",
                                },
                              }}
                            >
                              {[
                                {
                                  name: "Account",
                                  icon: (
                                    <img
                                      src={Accout}
                                      alt="account"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Wishlist",
                                  icon: (
                                    <img
                                      src={wishlist}
                                      alt="wishlist"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "History",
                                  icon: (
                                    <img
                                      src={History}
                                      alt="history"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Contact",
                                  icon: (
                                    <img
                                      src={Contact}
                                      alt="contact"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Sign Out",
                                  icon: (
                                    <img
                                      src={logout}
                                      alt="sign out"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                              ].map((item, index) => (
                                <Box key={item.name}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "12px",
                                      borderLeft: "1.321px solid transparent",
                                      marginLeft: "8px",
                                      background: "transparent",
                                      transition: "background 0.3s ease",
                                      cursor: "pointer",
                                      paddingLeft: "8px", // Adds space between the border and content
                                      "&:hover": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                      "&:active": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                    }}
                                    onClick={() =>
                                      handleMenuClickProfile(item.name)
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Inter",
                                        fontSize: {
                                          xs: "16px",
                                          md: "18.499px",
                                        }, // Adjust font size for different screen sizes
                                        fontWeight: 500,
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(255, 93, 239, 1) "
                                            : "rgba(255, 255, 255, 1)",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        marginLeft: "auto", // Pushes the icon to the right
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(219, 0, 126, 1)"
                                            : "rgba(255, 255, 255, 1)",
                                      }}
                                    >
                                      {item.icon}
                                    </Box>
                                  </Box>
                                  {index < 4 && (
                                    <Box
                                      sx={{
                                        borderRadius: "13.214px",
                                        background:
                                          "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                        height: "1.321px",
                                        alignSelf: "stretch",
                                        margin: {
                                          xs: "4px 8px",
                                          md: "8px 16px",
                                        }, // Adjust margin for different screen sizes
                                      }}
                                    />
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={2} lg={2}></Grid>
          </Grid>
        </Container>
        {/* </Container> */}

        <Grid
          container
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
          justifyContent="center"
        >
          <Box
            sx={{
              margin: "auto",
              position: "relative",
              height: "42px",
              top: "30px",
              // width: "400px", // Adjust as needed
              width: "359px", // Adjust as needed
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
              borderRadius: "99px",
              mixBlendMode: "initial",
              background: "rgba(154, 135, 166, 0.54)",

              boxShadow:
                "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
              left: 0,
              // backdrop-filter: blur(10px);
              backdropFilter: "blur(10px)",

              right: 0,
            }}
          >
            <Box
              style={{
                position: "absolute",
                overflow: "hidden !important",
                padding: "1.1px", // Adjust padding size
                borderRadius: "99px",
                // width: "400px", // Adjust as needed
                width: "360px", // Adjust as needed
                height: "42px",
                borderColor: "transparent",

                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                borderImageSlice: "1",
                mixBlendMode: "overlay",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              }}
            />

            <Grid
              container
              sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
              columns={12}
            >
              <Grid item xs={6}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  startIcon={<MenuIcon />}
                  sx={{ color: "white", padding: "10px" }}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      backgroundColor: "#5E4E6C", // Set your desired background color here
                      width: "150px", // Set your desired width here
                      backdropFilter: "blur(10px)",
                      color: "white",
                      borderRadius: "9px",
                    },
                    "& .MuiMenuItem-root": {
                      color: "white", // Set the text color of MenuItem
                    },
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Button onClick={() => handleButtonClick("/")}>
                      <img
                        src={GroupImage}
                        alt="GroupImage"
                        style={{
                          width: "31px",
                          height: "31px",
                          cursor: "pointer",
                          alignItems: "flex-end",
                          position: "relative",
                          bottom: "-5px",
                        }}
                      />
                    </Button>
                  </MenuItem>
                  {/* {pages.map((page) => (
                    <MenuItem key={page} onClick={handleClose}>
                      {page}
                    </MenuItem>
                  ))} */}

                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/homePage")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/homePage"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      Home
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/movies")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/movies"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      Movies
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/tvSeriesPage")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/tvSeriesPage"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      TV Series
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/songs")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/songs" ? activeButtonStyle : {}),
                      })}
                    >
                      Songs
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/livetv")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/livetv"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      Live TV
                    </Button>
                  </MenuItem>

                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "flex-end",
                    }}
                  ></Box> */}
                </Menu>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                // onClick={() => handleButtonClick("/")}
              >
                <Box>
                  {isLoggedIn ? (
                    <Button
                      style={{
                        height: "43px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                      onClick={handleProfileClick}
                    >
                      <img src={profile} alt="profile" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleClickOpen}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        width: "83px",
                        padding:
                          "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                        gap: "var(--Buttongap)",
                        borderRadius: "99px",
                        border: "1px solid transparent",
                        fontFamily: "Inter",
                        background:
                          "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                        boxShadow:
                          "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                        fontSize: "14px",
                        borderImage:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                        textTransform: "initial",
                        backdropFilter: "blur(10px)",
                        marginTop: "5px",
                      })}
                    >
                      Sign in
                    </Button>
                  )}
                </Box>

                {isProfileDropdownOpen && (
                  <Box
                    sx={{
                      height: { xs: "auto", md: "304.85px" },
                      borderRadius: "13.21px",
                      position: "absolute", // Changed to absolute positioning
                      background:
                        "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                      width: {
                        xs: "100%",
                        sm: "300px",
                        md: "264.27px",
                      },
                      left: {
                        xs: 0,
                        md: "auto",
                        lg: "auto",
                        sm: "auto",
                      },
                      right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                      top: {
                        xs: "50px",
                        md: "50px",
                        lg: "90px",
                        sm: "50px",
                      },
                      backdropFilter: "blur(13.21367073059082px)",
                      zIndex: 9999, // Ensure it overlays other content
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "13.21px",
                        height: "100%",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        padding: "2px",
                        zIndex: "20",
                        overflow: "hidden",
                        background:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                        boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        fontFamily: "Inter important",
                        fontWeight: 700,
                        padding: {
                          xs: "16px",
                          md: "24px",
                          lg: "24px",
                          sm: "16px",
                        },
                      }}
                    >
                      {[
                        {
                          name: "Account",
                          icon: (
                            <img
                              src={Accout}
                              alt="account"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Wishlist",
                          icon: (
                            <img
                              src={wishlist}
                              alt="wishlist"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "History",
                          icon: (
                            <img
                              src={History}
                              alt="history"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Contact",
                          icon: (
                            <img
                              src={Contact}
                              alt="contact"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Sign Out",
                          icon: (
                            <img
                              src={logout}
                              alt="sign out"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                      ].map((item, index) => (
                        <Box key={item.name}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "12px",
                              borderLeft: "1.321px solid transparent",
                              marginLeft: "8px",
                              background: "transparent",
                              transition: "background 0.3s ease",
                              cursor: "pointer",
                              paddingLeft: "8px", // Adds space between the border and content
                              "&:hover": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                              "&:active": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                            }}
                            onClick={() => handleMenuClickProfile(item.name)}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Inter",
                                fontSize: {
                                  xs: "16px",
                                  md: "18.499px",
                                }, // Adjust font size for different screen sizes
                                fontWeight: 500,
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(255, 93, 239, 1) "
                                    : "rgba(255, 255, 255, 1)",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "auto", // Pushes the icon to the right
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(219, 0, 126, 1)"
                                    : "rgba(255, 255, 255, 1)",
                              }}
                            >
                              {item.icon}
                            </Box>
                          </Box>
                          {index < 4 && (
                            <Box
                              sx={{
                                borderRadius: "13.214px",
                                background:
                                  "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                height: "1.321px",
                                alignSelf: "stretch",
                                margin: {
                                  xs: "4px 8px",
                                  md: "8px 16px",
                                }, // Adjust margin for different screen sizes
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
          justifyContent="center"
        >
          <Box
            sx={{
              margin: "auto",
              position: "relative",
              height: "42px",
              top: "30px",
              width: "500px", // Adjust as needed
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
              borderRadius: "99px",
              mixBlendMode: "initial",
              background: "rgba(154, 135, 166, 0.54)",

              boxShadow:
                "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
              left: 0,
              // backdrop-filter: blur(10px);
              backdropFilter: "blur(10px)",

              right: 0,
            }}
          >
            <Box
              style={{
                position: "absolute",
                overflow: "hidden !important",
                padding: "1.1px", // Adjust padding size
                borderRadius: "99px",
                width: "500px", // Adjust as needed
                height: "42px",
                borderColor: "transparent",

                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                borderImageSlice: "1",
                mixBlendMode: "overlay",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              }}
            />

            <Grid
              container
              sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
              columns={12}
            >
              <Grid item xs={6}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  startIcon={<MenuIcon />}
                  sx={{ color: "white", padding: "10px" }}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      backgroundColor: "#5E4E6C", // Set your desired background color here
                      width: "150px", // Set your desired width here
                      backdropFilter: "blur(10px)",
                      color: "white",
                      borderRadius: "9px",
                    },
                    "& .MuiMenuItem-root": {
                      color: "white", // Set the text color of MenuItem
                    },
                  }}
                >
                  {/* {pages.map((page) => (
                    <MenuItem key={page} onClick={handleClose}>
                      {page}
                    </MenuItem>
                  ))} */}

                  {/* <Box
                        sx={{
                          width: "392px",
                          height: "32px",
                          gap: "20px",
                          // display: "flex",
                        }}
                      > */}
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/homePage")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/homePage"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      Home
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/movies")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/movies"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      Movies
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/tvSeriesPage")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/tvSeriesPage"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      TV Series
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/songs")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/songs" ? activeButtonStyle : {}),
                      })}
                    >
                      Songs
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/livetv")}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === "/livetv"
                          ? activeButtonStyle
                          : {}),
                      })}
                    >
                      Live TV
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button onClick={() => handleButtonClick("/")}>
                      <img
                        src={GroupImage}
                        alt="GroupImage"
                        style={{
                          width: "31px",
                          height: "31px",
                          cursor: "pointer",
                          alignItems: "flex-end",
                          position: "relative",
                          bottom: "-5px",
                        }}
                      />
                    </Button>
                  </MenuItem>
                </Menu>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                // onClick={() => handleButtonClick("/")}
              >
                <Box>
                  {isLoggedIn ? (
                    <Button
                      style={{
                        height: "43px",
                        display: "flex",
                        justifyContent: "end",
                        // padding: "0 !important",
                      }}
                      onClick={handleProfileClick}
                    >
                      <img src={profile} alt="profile" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleClickOpen}
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        width: "83px",
                        padding:
                          "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                        gap: "var(--Buttongap)",
                        borderRadius: "99px",
                        border: "1px solid transparent",
                        fontFamily: "Inter",
                        background:
                          "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                        boxShadow:
                          "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                        fontSize: "14px",
                        borderImage:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                        textTransform: "initial",
                        backdropFilter: "blur(10px)",
                        marginTop: "5px",
                      })}
                    >
                      Sign in
                    </Button>
                  )}
                </Box>
                {isProfileDropdownOpen && (
                  <Box
                    sx={{
                      height: { xs: "auto", md: "304.85px" },
                      borderRadius: "13.21px",
                      position: "absolute", // Changed to absolute positioning
                      background:
                        "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                      width: {
                        xs: "100%",
                        sm: "300px",
                        md: "264.27px",
                      },
                      left: {
                        xs: 0,
                        md: "auto",
                        lg: "auto",
                        sm: "auto",
                      },
                      right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                      top: {
                        xs: "50px",
                        md: "50px",
                        lg: "90px",
                        sm: "50px",
                      },
                      backdropFilter: "blur(13.21367073059082px)",
                      zIndex: 9999, // Ensure it overlays other content
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "13.21px",
                        height: "100%",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        padding: "2px",
                        zIndex: "20",
                        overflow: "hidden",
                        background:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                        boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        fontFamily: "Inter important",
                        fontWeight: 700,
                        padding: {
                          xs: "16px",
                          md: "24px",
                          lg: "24px",
                          sm: "16px",
                        },
                      }}
                    >
                      {[
                        {
                          name: "Account",
                          icon: (
                            <img
                              src={Accout}
                              alt="account"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Wishlist",
                          icon: (
                            <img
                              src={wishlist}
                              alt="wishlist"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "History",
                          icon: (
                            <img
                              src={History}
                              alt="history"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Contact",
                          icon: (
                            <img
                              src={Contact}
                              alt="contact"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Sign Out",
                          icon: (
                            <img
                              src={logout}
                              alt="sign out"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                      ].map((item, index) => (
                        <Box key={item.name}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "12px",
                              borderLeft: "1.321px solid transparent",
                              marginLeft: "8px",
                              background: "transparent",
                              transition: "background 0.3s ease",
                              cursor: "pointer",
                              paddingLeft: "8px", // Adds space between the border and content
                              "&:hover": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                              "&:active": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                            }}
                            onClick={() => handleMenuClickProfile(item.name)}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Inter",
                                fontSize: {
                                  xs: "16px",
                                  md: "18.499px",
                                }, // Adjust font size for different screen sizes
                                fontWeight: 500,
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(255, 93, 239, 1) "
                                    : "rgba(255, 255, 255, 1)",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "auto", // Pushes the icon to the right
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(219, 0, 126, 1)"
                                    : "rgba(255, 255, 255, 1)",
                              }}
                            >
                              {item.icon}
                            </Box>
                          </Box>
                          {index < 4 && (
                            <Box
                              sx={{
                                borderRadius: "13.214px",
                                background:
                                  "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                height: "1.321px",
                                alignSelf: "stretch",
                                margin: {
                                  xs: "4px 8px",
                                  md: "8px 16px",
                                }, // Adjust margin for different screen sizes
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
                xxl: "flex",
              },
            }}
          >
            <Grid
              item
              md={2}
              lg={2}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",
                  marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              md={10}
              lg={10}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "44px",
                    top: "30px",
                    width: "10px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      width: "100px",
                      height: "44px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={8} md={8} sm={8}>
                      <Box
                        sx={{
                          width: "392px",
                          height: "32px",
                          gap: "20px",
                          display: "flex",
                        }}
                      >
                        <Button
                          onClick={() => handleButtonClick("/homePage")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/homePage"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          Home
                        </Button>
                        <Button
                          onClick={() => handleButtonClick("/movies")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/movies"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          Movies
                        </Button>
                        <Button
                          onClick={() => handleButtonClick("/tvSeriesPage")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/tvSeriesPage"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          TV Series
                        </Button>
                        <Button
                          onClick={() => handleButtonClick("/livetv")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/livetv"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          Live TV
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={3} lg={3} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        {["/homePage", "/movies", "/tvSeriesPage"].includes(
                          activeButton
                        ) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "32px",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              onClick={() => {
                                // Handle search icon click
                              }}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "45px",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              style={{
                                ...buttonStyle,
                                width: "72px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              Log in
                            </Button>
                            <Button
                              sx={(theme) => ({
                                ...buttonStyle(theme),
                                width: "83px",
                                padding:
                                  "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                gap: "var(--Buttongap)",
                                borderRadius: "99px",
                                border: "1px solid transparent",
                                fontFamily: "Inter",
                                background:
                                  "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                boxShadow:
                                  "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                fontSize: "14px",
                                borderImage:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                textTransform: "initial",
                                backdropFilter: "blur(10px)",
                                marginTop: "2px",
                              })}
                            >
                              Sign in
                            </Button>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={2} lg={2}></Grid>
          </Grid>
        </Container>
      </AppBar>

      <AuthDialog
        open={open}
        handleCloseDialog={handleCloseDialog}
        // handleSend={handleSend}
        phone={phone}
        setPhone={setPhone}
        code={code}
        handleCloseCode={handleCloseCode}
        verificationCode={verificationCode}
        setVerificationCode={setVerificationCode}
        otp={otp}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        setOtp={setOtp} // Pass setOtp here
        // handleInputChange={handleInputChange}
        // verifyOtp={verifyOtp}
        resendTimer={resendTimer}
        handleResendCode={handleResendCode}
        login={login}
        appleLogin={appleLogin}
        sendOtp={sendOtp}
        verifySessionOtp={verifySessionOtp}
        loginData={loginData}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={5000}
        imgSrc={snackbarImage} // Dynamically pass the image URL
      />
    </>
  );
};

export default Header;
