// import React from 'react';
// import loaderbackground from '../images/loader_bg.png';
// import loaderborder from '../images/loader_border.png';
// import loadere from '../images/loader_e.png';

// function loader() {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100vh',
//         textAlign: 'center',
//       }}
//     >
//       {/* Loader background and E image (static) */}
//       <div
//         style={{
//           position: 'relative',
//           width: '150px',
//           height: '150px',
//         }}
//       >
//         {/* Loader background */}
//         <div
//           style={{
//             position: 'absolute',
//             width: '100%',
//             height: '100%',
//             backgroundImage: `url(${loaderbackground})`,
//             backgroundSize: 'contain',
//             backgroundRepeat: 'no-repeat',
//           }}
//         />

//         {/* Loader E image */}
//         <div
//           style={{
//             position: 'absolute',
//             width: '100%',
//             height: '100%',
//             backgroundImage: `url(${loadere})`,
//             backgroundSize: 'contain',
//             backgroundRepeat: 'no-repeat',
//           }}
//         />
//       </div>

//       {/* Rotating border image */}
//       <div
//         style={{
//           position: 'absolute',
//           width: '150px',
//           height: '150px',
//           backgroundImage: `url(${loaderborder})`,
//           backgroundSize: 'contain',
//           backgroundRepeat: 'no-repeat',
//           animation: 'rotate 2s linear infinite',
//         }}
//       />

//       {/* Keyframes for rotating animation */}
//       <style>
//         {`
//           @keyframes rotate {
//             from {
//               transform: rotate(0deg);
//             }
//             to {
//               transform: rotate(360deg);
//             }
//           }
//         `}
//       </style>
//     </div>
//   );
// }

// export default loader;

import React from "react";
import loaderbackground from "../../pages/images/loader_bg.png";
import loaderborder from "../../pages/images/loader_border.png";
import loadere from "../../pages/images/loader_e.png";                                                                                                                                                                         

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        // backgroundColor: 'black',  // Set background color to black
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100px",
          height: "100px",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: `url(${loaderbackground})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: `url(${loadere})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
       <div
        style={{
          position: "absolute",
          width: "100px",
          height: "100px",
          backgroundImage: `url(${loaderborder})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          animation: "rotate 2s linear infinite",
        }}
      />
      <style>
        {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
}

export default Loader;
